<template>
	<div>
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 充值配置
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="handle-box" style="margin: 5px 0;">
			<el-button @click="addbeans">新增</el-button>
		</div>
		<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
			<el-table-column prop="money" label="金额" align="center"></el-table-column>
			<el-table-column prop="beans" label="福豆" align="center"></el-table-column>
			<el-table-column label="操作" width="180" align="center">
				<template #default="scope">
					<el-button type="text" icon="el-icon-edit" @click="editbeans(scope.row)">编辑</el-button>
					<el-button type="text" icon="el-icon-delete" @click="deletebeans(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination background layout="total, prev, pager, next" :current-page="query.page"
				:page-size="query.limit" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
		</div>

		<el-dialog title="编辑/新增配置" v-model="addVisible" width="30%">
			<el-form ref="form" :model="add_query" label-width="80px">
				<el-form-item label="金额">
					<el-input v-model="add_query.money"></el-input>
				</el-form-item>
				<el-form-item label="福豆">
					<el-input v-model="add_query.beans"></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="addVisible = false">取 消</el-button>
					<el-button type="primary" @click="save">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import PAGE_CONFIG from '@/utils/config'
	export default {
		data() {
			return {
				tableData: [],
				query: {
					page: 1,
					limit: 15,
					title: '',
				},
				pageTotal: 0,
				defaultUrl: PAGE_CONFIG.defaultUrl,
				addVisible: false,
				add_query: {
					money: '',
					beans: ''
				},
				inputVal: '',
			}
		},
		watch: {
			addVisible(val) {
				if (!val) {
					this.add_query = {
						money: '',
						beans: ''
					}
				}
			}
		},
		created() {
			this.getList()
		},
		methods: {
			handlePageChange(val) {
				this.query.page = val
				this.getList();
			},
			getList() {
				this.$axios({
					url: '/admin/beans/getList',
					method: 'get',
					params: {
						...this.query
					}
				}).then((res) => {
					if (res.code == 200) {
						this.tableData = res.data.data
						this.pageTotal = res.data.total
					}
				}).catch(err => {
					console.log(err)
				});
			},
			editbeans(item) {
				this.add_query = {
					id: item.id,
					money: item.money,
					beans: item.beans
				}
				this.addVisible = true
			},
			addbeans() {
				this.addVisible = true
			},
			deletebeans(item) {
				this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.delItem(item)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			delItem(item) {
				this.$axios({
					url: '/admin/beans/delete',
					method: 'delete',
					params: {
						id: item.id
					}
				}).then(({
					code
				}) => {
					if (code == 200) {
						this.getList()
						this.$message.success("操作成功")
					}
				}).catch(err => {
					console.log(err)
				});
			},
			save() {
				this.addVisible = true
				let url = `/admin/beans/${this.add_query.id?'edit':'add'}`
				this.$axios({
					url,
					method: 'post',
					data: {
						...this.add_query
					}
				}).then((res) => {
					if (res.code == 200) {
						this.addVisible = false
						this.getList()
					}
				}).catch(err => {
					console.log(err)
				});
			},
			uploadClick() {
				document.getElementById('image-input').click();
			},
			chooseimage(e) {
				this.uploadFile(e.target.files);
			},
			uploadFile(files) {
				var file = files[0];
				let data = new FormData()
				data.append('image', file)
				this.$axios({
					url: 'admin/upImg',
					method: 'post',
					data,
				}).then(({
					code,
					data,
					msg
				}) => {
					this.inputVal = ""
					if (code == 200) {
						this.add_query.icon = data.url
					} else {
						this.$message.error(msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},
		}
	}
</script>

<style scoped>

</style>
